import React from 'react'
import Layout from '../components/layout'

import { downloads } from '../conf/downloads'

import pdf from '../images/pdf.png'

const renderDownloads = (group) => {
    return downloads.map((post, index) => {
        if (group === post.group) {
            return (
                <div className="col-md-6 col-sm-12" style={{ marginBottom: '24px' }} key={"post_" + index}>
                    <div className="download-card">
                        <img src={pdf} height="40px" width="40px" alt="pdf" />
                        <h4>{post.title}</h4>
                        <h5>{post.text}</h5>
                        <a href={post.file} target="_blank" rel="noopener noreferrer" className="button-link">Download</a>
                    </div>
                </div >
            )
        }
    })
}


const DownloadForm = () => (
    <Layout>
        <section id="downloads" className="section-wrapper">
            <header className="section-header">
                <h3>Downloads</h3>
            </header>
            <h2>Abacus HR</h2>
            <div className="row">
                {renderDownloads('HR')}
            </div>
            <h2>Abacus Zeit</h2>
            <div className="row">
                {renderDownloads('Zeit')}
            </div>
            <h2>Berichte</h2>
            <div className="row">
                {renderDownloads('Bericht')}
            </div>
        </section>
    </Layout>
)


export default DownloadForm
