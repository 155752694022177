import file1 from '../downloads/Abacus Human Resources LEHMANNPARTNER.pdf'
import file2 from '../downloads/Abacus Lohnbuchhaltung LEHMANNPARTNER.pdf'
import file3 from '../downloads/Abacus Zeiterfassung Funktionen LEHMANNPARTNER.pdf'
import file4 from '../downloads/Abacus Mitarbeiterportal LEHMANNPARTNER.pdf'
import file5 from '../downloads/Abacus Mitarbeitergespräche LEHMANNPARTNER.pdf'
import file6 from '../downloads/Abacus Rekrutierung LEHMANNPARTNER.pdf'
import file7 from '../downloads/Abacus Zeiterfassung LEHMANNPARTNER.pdf'
import file8 from '../downloads/Abacus Arbeitszeugnis LEHMANNPARTNER.pdf'
import file9 from '../downloads/Abacus HR Mobile AbaClik.pdf'
import file10 from '../downloads/Abacus-LEHMANN-und-PARTNER.pdf'
import file11 from '../downloads/Abacus Zeiterfassung Testimonial Sertronics.pdf'
import file12 from '../downloads/Abacus Human Resources Funktionen LEHMANNPARTNER.pdf'
import file13 from '../downloads/Abacus Zeiterfassung Flyer LEHMANNPARTNER.pdf'
import file14 from '../downloads/Abacus Pages 2020 Abacus HR AKB.pdf'

export const downloads = [
    {
        title: 'Flyer Abacus HR',
        text: 'Informationen zu Abacus HR',
        file: file1,
        fileType: 'PDF',
        group: 'HR'
    },
    {
        title: 'Funktionen Abacus HR und Payroll',
        text: 'Informationen zu Abacus HR und Payroll',
        file: file12,
        fileType: 'PDF',
        group: 'HR'
    },
    {
        title: 'Flyer Abacus Payroll',
        text: 'Informationen zu Abacus Payroll',
        file: file2,
        fileType: 'PDF',
        group: 'HR'
    },
    {
        title: 'Flyer Abacus Zeiterfassung',
        text: 'Informationen zu Abacus Zeit- und Spesenerfassung',
        file: file13,
        fileType: 'PDF',
        group: 'Zeit'
    },
    {
        title: 'Funktionen Abacus Zeiterfassung',
        text: 'Funktionsumfang der Abacus Zeit- und Spesenerfassung',
        file: file3,
        fileType: 'PDF',
        group: 'Zeit'
    },
    {
        title: 'Flyer MyAbacus HR Portal',
        text: 'Informationen zum Abacus HR Portal',
        file: file4,
        fileType: 'PDF',
        group: 'HR'
    },
    {
        title: 'Flyer Abacus Mitarbeitergespräche',
        text: 'Informationen zum HR Prozess Abacus Mitarbeitergespräche',
        file: file5,
        fileType: 'PDF',
        group: 'HR'
    },
    {
        title: 'Flyer Abacus Bewerbermanagement',
        text: 'Informationen zum HR Prozess Abacus Bewerbermanagement',
        file: file6,
        fileType: 'PDF',
        group: 'HR'
    },
    {
        title: 'Flyer Abacus Zeitmanagement',
        text: 'Informationen zur Abacus Zeit- und Spesenerfassung',
        file: file7,
        fileType: 'PDF',
        group: 'Zeit'
    },
    {
        title: 'Flyer Abacus Zeugnis',
        text: 'Informationen zum HR Prozess Abacus Zeugniserstellung',
        file: file8,
        fileType: 'PDF',
        group: 'HR'
    },
    {
        title: 'Flyer Abacus AbaClik',
        text: 'Abacus HR Mobile via AbaClik',
        file: file9,
        fileType: 'PDF',
        group: 'Zeit'
    },
    {
        title: 'Abacus HR',
        text: 'Testimonial Aargauische Kantonalbank',
        file: file14,
        fileType: 'PDF',
        group: 'Bericht'
    },
    {
        title: 'Abacus 2020',
        text: 'Wichtigste Neuerungen der Version 2020',
        file: 'https://classic.abacus.ch/fileadmin/mediendatenbank/sonderdrucke/neuerungen/sd_neuerungen_2020_de.pdf',
        fileType: 'PDF',
        group: 'Bericht'
    },
    {
        title: 'Abacus Zeiterfassung',
        text: 'Testimonial Sertronics',
        file: file11,
        fileType: 'PDF',
        group: 'Bericht'
    },
    {
        title: 'Zusammenarbeit',
        text: 'Abacus und LEHMANN+PARTNER spannen zusammen',
        file: file10,
        fileType: 'PDF',
        group: 'Bericht'
    }
]